<template>
  <div
    v-if="assignedContainerDiffrentThanOrdered"
    class="mb-4 d-flex align-center"
  >
    <v-icon
      size="20"
      color="red"
    >
      mdi-alert
    </v-icon>
    <span class="warning-message ml-2">{{
      assignedContainerDiffrentThanOrdered
    }}</span>
  </div>
</template>

<script>
export default {
  props: {
    order: {
      type: Object,
      required: true
    },
  },
  computed: {
    assignedContainerDiffrentThanOrdered() {
      const assignedContainer = this.order?.assignedContainerTypeId
      const orderedContainer = this.order?.containerTypeId
      if (orderedContainer && assignedContainer) {
        return assignedContainer !== orderedContainer
          ? 'Podstawiono inny rozmiar kontenera niż był zamówiony'
          : ''
      } return ''
    },
  },
}
</script>
