import { getGrossValue } from './index'

function updateOrderFields (order) {
  const { containerType, course, debrisType, phoneNumber, userNotes } = order
  this.emitUpdate('order.debrisTypeId', debrisType?.id)
  this.emitUpdate('order.phoneNumber', phoneNumber)
  this.emitUpdate('order.userNotes', userNotes)
  this.emitUpdate('order.containerTypeId', containerType?.id)
  this.emitUpdate('order.pickupContainerTypeId', containerType?.id)
  this.emitUpdate('course.fromTime', course.timeSlot[0])
  this.emitUpdate('course.toTime', course.timeSlot[1])
  this.emitUpdate('course.userNotes', course.userNotes)
}
function updateProductFields (product) {
  const { containerType, debrisType, netValue, vatPercentage } = product
  const debrisNetValue = netValue / 100
  this.emitUpdate('order.debrisTypeId', debrisType?.id)
  this.emitUpdate('order.containerTypeId', containerType?.id)
  this.emitUpdate('payment.debrisNetValue', debrisNetValue)
  this.emitUpdate('payment.debrisGrossValue', getGrossValue(debrisNetValue, vatPercentage))
}

function updatePaymentFields (order) {
  const { address, payment, vatPercentage } = order
  const debrisNetValue = payment?.debrisNetValue / 100
  this.emitUpdate('payment.debrisNetValue', debrisNetValue)
  this.emitUpdate('payment.debrisGrossValue', getGrossValue(debrisNetValue, vatPercentage))
  this.emitUpdate('payment.paymentType', payment?.paymentType)
  this.emitUpdate('payment.settlementType', payment?.settlementType)
  this.emitUpdate('payment.discount', payment?.discount / 100)
  this.emitUpdate('payment.discountJustification', payment?.discountJustification)
  this.emitUpdate('locationDiscount', address?.discount / 100)
}

export {
  updateOrderFields,
  updateProductFields,
  updatePaymentFields
}
